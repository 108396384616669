<template>
  <div class="calendar">
    <div class="calendar-header">
      <div class="child-a">
        <el-link
          @click="SetTodayDate"
          style="font-size: 12.5px"
          :underline="false"
          class="mt-1 ml-1"
          >Today</el-link
        >
      </div>
      <div class="child-a">
        <div class="d-flex">
          <el-link
            @click="prev"
            icon="el-icon-arrow-left"
            :underline="false"
            class="mr-1"
          ></el-link>
          <p :class="{ centered: shouldCenterMonth }" class="mt-1">
            {{ getCurrentDate() }}
          </p>
          <el-link
            @click="next"
            icon="el-icon-arrow-right"
            :underline="false"
            class="ml-1"
          ></el-link>
        </div>
      </div>

      <div class="child-a">
        <el-select
          style="width: 50%"
          size="mini"
          v-model="currentView"
          placeholder="Select"
          @change="changeView"
          class="float-right mt-1 mr-2"
        >
          <el-option value="Month">Month</el-option>
          <el-option value="Week">Week</el-option>
          <el-option value="Day">Day</el-option>
        </el-select>
      </div>
    </div>
    <div v-if="currentView === 'Month'" class="monthView">
      <table>
        <thead>
          <tr>
            <th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(week, i) in weeks" :key="'week' + i">
            <td
              v-for="(day, j) in week"
              :key="'day' + j"
              :style="
                isCurrentDay(day)
                  ? 'background-color: lightblue;'
                  : getFirstEvent(day)
                  ? `background-color: ${getFirstEvent(day).color};`
                  : ''
              "
            >
              <div
                style="width: 100%; cursor: pointer"
                @click="openNewEvent(day, 'MONTH')"
              >
                <el-row style="position: absolute; top: 0px; right: 15px">
                  <el-col :span="2" :offset="18">
                    {{ day }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-popover
                    placement="right"
                    trigger="hover"
                    v-if="
                      (getDateEvent(day) && getDateEvent(day).length) ||
                      (getDateRangeEvent(day) && getDateRangeEvent(day).length)
                    "
                  >
                    <div>
                      <!-- Date Header -->
                      <div class="today-date">
                        <p>
                          {{
                            monthsNames[currentDate.getMonth()] +
                            " " +
                            day +
                            "-" +
                            currentDate.getFullYear()
                          }}
                        </p>
                      </div>
                      <el-scrollbar wrap-style="max-height: 300px !important;">
                        <div
                          v-for="(ev, index) in getDateEvent(day)"
                          :key="day + 'day_month_view' + index"
                          class="d-flex p-2"
                          @click="gotoEntityData(ev)"
                          :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                        >
                          <div
                            :style="`border-left: 3px solid ${
                              ev.color ? ev.color : '#f754a2'
                            }; padding-left: 5px;border-radius:3px 3px;`"
                            class="d-flex"
                          >
                            {{ ev.customData }}
                            <div
                              class="ml-1 customdate"
                              v-if="ev.inputType == 'DATE_TIME'"
                            >
                              {{ ev.dates | moment("h:mm A") }}
                            </div>
                            <div class="ml-2" v-else></div>
                          </div>
                        </div>
                        <div
                          v-for="(ev, index) in getDateRangeEvent(day)"
                          :key="day + 'day_view_hour' + index"
                          class="d-flex p-2"
                          @click="gotoEntityData(ev)"
                          :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                        >
                          <div
                            :style="`border-left: 3px solid ${
                              ev.color ? ev.color : '#f754a2'
                            }; padding-left: 5px;border-radius:3px 3px;`"
                            class="d-flex"
                          >
                            {{ ev.customData }}
                            <div
                              class="ml-1"
                              v-if="
                                (ev.inputType == 'DATE_TIME_RANGE' ||
                                  ev.inputType == 'DATE_RANGE') &&
                                ev.dates &&
                                ev.dates[0]
                              "
                              style="font-size: 10px; font-weight: bold"
                            >
                              <p>
                                {{ ev.dates[0] | moment("MM-DD-YYYY h:mm A")
                                }}<br />
                                {{ ev.dates[1] | moment("MM-DD-YYYY h:mm A") }}
                              </p>
                            </div>
                            <div class="ml-2" v-else></div>
                          </div>
                        </div>
                      </el-scrollbar>
                    </div>
                    <div slot="reference">
                      <el-row class="event-container">
                        <div
                          v-for="(ev, index) in (getDateEvent(day) || []).slice(
                            0,
                            3
                          )"
                          :key="day + index + 'month_view'"
                          class="single-event"
                          :style="`background-color: ${
                            ev.color ? ev.color : '#f754a2'
                          };`"
                        ></div>
                      </el-row>
                      <el-row
                        v-if="
                          getDateRangeEvent(day) &&
                          getDateRangeEvent(day).length
                        "
                        style="top: 15px"
                      >
                        <div
                          :style="`background-color: ${
                            getDateRangeEvent(day)[0].color
                              ? getDateRangeEvent(day)[0].color
                              : '#f754a2'
                          }; width: auto !important; height: 4px;`"
                        ></div>
                      </el-row>
                      <div
                        v-if="getDateEvent(day) && getDateEvent(day).length > 3"
                        :style="`font-size: 10px;`"
                      >
                        {{ "+" + (getDateEvent(day).length - 3) }}
                      </div>
                    </div>
                  </el-popover>
                </el-row>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="currentView === 'Week'" class="weekView">
      <el-scrollbar wrap-style="max-height: 300px !important;">
        <table>
          <thead>
            <tr>
              <th></th>
              <th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Time</td>
              <td
                v-for="day in weekDays"
                :key="day.day"
                :class="{ 'current-day': isCurrentDay(day.day) }"
              >
                <!-- {{ day }} -->
                <div style="width: 100%">
                  <div>{{ day.day }}</div>
                  <div></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>All day</td>
              <td
                v-for="day in weekDays"
                :key="day.day"
                @click="openNewEvent(day.day, 'WEEK')"
              >
                <div
                  v-if="
                    getWeekEvent(day.day, null) &&
                    getDayRangeWeekEvent(day.day, null, day.month)
                  "
                >
                  <el-popover
                    class="popoverText"
                    placement="right"
                    trigger="hover"
                    v-if="
                      (getWeekEvent(day.day, null) &&
                        getWeekEvent(day.day, null).length) ||
                      (getDayRangeWeekEvent(day.day, null, day.month) &&
                        getDayRangeWeekEvent(day.day, null, day.month).length)
                    "
                  >
                    <div class="today-date">
                      <p>
                        {{
                          monthsNames[currentDate.getMonth()] +
                          " " +
                          day.day +
                          "-" +
                          currentDate.getFullYear()
                        }}
                      </p>
                    </div>
                    <el-scrollbar wrap-style="max-height: 300px !important;">
                      <div
                        v-for="(ev, index) in getWeekEvent(day.day, null)"
                        :key="day.day + index + 'weekday'"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                        </div>
                      </div>
                      <div
                        v-for="(ev, index) in getDayRangeWeekEvent(
                          day.day,
                          null,
                          day.month
                        )"
                        :key="day.day + index + 'weekday1'"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                        </div>
                        <div
                          class="ml-1"
                          v-if="
                            (ev.inputType == 'DATE_TIME_RANGE' ||
                              ev.inputType == 'DATE_RANGE') &&
                            ev.dates &&
                            ev.dates[0]
                          "
                          style="font-size: 10px; font-weight: bold"
                        >
                          <p>
                            {{ ev.dates[0] | moment("MM-DD-YYYY h:mm A")
                            }}<br />
                            {{ ev.dates[1] | moment("MM-DD-YYYY h:mm A") }}
                          </p>
                        </div>
                      </div>
                    </el-scrollbar>
                    <div class="weekday-container" slot="reference">
                      <div
                        v-for="(ev, index) in (
                          getWeekEvent(day.day, null) || []
                        ).slice(0, 2)"
                        :key="day.day + index + 'day_view'"
                        class="weekday-single"
                        :style="`background-color: ${
                          ev.color ? ev.color : '#f754a2'
                        }`"
                      >
                        {{ ev.customData | truncate(8, "...") }}
                      </div>
                      <div
                        v-if="
                          getDayRangeWeekEvent(day.day, null, day.month) &&
                          getDayRangeWeekEvent(day.day, null, day.month)[0]
                        "
                      >
                        <div
                          :style="`background-color: ${
                            getDayRangeWeekEvent(day.day, null, day.month)[0]
                              .color
                              ? getDayRangeWeekEvent(
                                  day.day,
                                  null,
                                  day.month
                                )[0].color
                              : '#f754a2'
                          }; width: auto !important; height: 4px;`"
                        ></div>
                      </div>
                      <div
                        v-if="
                          getWeekEvent(day.day, null) &&
                          getWeekEvent(day.day, null).length > 2
                        "
                        :style="`font-size: 10px;`"
                      >
                        {{ "+" + (getWeekEvent(day.day, null).length - 2) }}
                      </div>
                    </div>
                  </el-popover>
                </div>
              </td>
            </tr>
            <tr v-for="hour in hours" :key="hour">
              <td>{{ hour }}</td>
              <td
                v-for="day in weekDays"
                :key="day.day"
                @click="openNewEvent(day.day, 'WEEK', hour)"
              >
                <div v-if="getWeekEvent(day.day, hour)">
                  <el-popover
                    placement="right"
                    trigger="hover"
                    v-if="
                      (getWeekEvent(day.day, hour) &&
                        getWeekEvent(day.day, hour).length) ||
                      (getDayRangeWeekEvent(day.day, hour, day.month) &&
                        getDayRangeWeekEvent(day.day, hour, day.month).length)
                    "
                  >
                    <div class="today-date">
                      <p>
                        {{
                          monthsNames[currentDate.getMonth()] +
                          " " +
                          day.day +
                          "-" +
                          currentDate.getFullYear()
                        }}
                      </p>
                    </div>
                    <el-scrollbar wrap-style="max-height: 300px !important;">
                      <div
                        v-for="(ev, index) in getWeekEvent(day.day, hour)"
                        :key="day.day + hour + index + 'day_hour_view'"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                          <div
                            class="ml-1 customdate"
                            v-if="ev.inputType == 'DATE_TIME'"
                          >
                            {{ ev.dates | moment("h:mm A") }}
                          </div>
                          <div class="ml-2" v-else></div>
                        </div>
                      </div>
                      <div
                        v-for="(ev, index) in getDayRangeWeekEvent(
                          day.day,
                          hour,
                          day.month
                        )"
                        :key="day.day + index + 'weekday2'"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                        </div>
                        <div
                          class="ml-1"
                          v-if="
                            (ev.inputType == 'DATE_TIME_RANGE' ||
                              ev.inputType == 'DATE_RANGE') &&
                            ev.dates &&
                            ev.dates[0]
                          "
                          style="font-size: 10px; font-weight: bold"
                        >
                          <p>
                            {{ ev.dates[0] | moment("MM-DD-YYYY h:mm A")
                            }}<br />
                            {{ ev.dates[1] | moment("MM-DD-YYYY h:mm A") }}
                          </p>
                        </div>
                      </div>
                    </el-scrollbar>
                    <div class="weekday-container" slot="reference">
                      <div
                        v-for="(ev, index) in (
                          getWeekEvent(day.day, hour) || []
                        ).slice(0, 3)"
                        :key="hour + index + 'hour_view'"
                        class="weekday-single"
                        :style="`background-color: ${
                          ev.color ? ev.color : '#f754a2'
                        }`"
                      >
                        {{ ev.customData | truncate(10, "...") }}
                      </div>
                      <div
                        v-if="
                          getDayRangeWeekEvent(day.day, hour, day.month) &&
                          getDayRangeWeekEvent(day.day, hour, day.month)[0]
                        "
                      >
                        <div
                          :style="`background-color: ${
                            getDayRangeWeekEvent(day.day, hour, day.month)[0]
                              .color
                              ? getDayRangeWeekEvent(
                                  day.day,
                                  hour,
                                  day.month
                                )[0].color
                              : '#f754a2'
                          }; width: auto !important; height: 4px;`"
                        ></div>
                      </div>
                      <div
                        v-if="
                          getWeekEvent(day.day, hour) &&
                          getWeekEvent(day.day, hour).length > 2
                        "
                        :style="`font-size: 10px;`"
                      >
                        {{ "+" + (getWeekEvent(day.day, hour).length - 2) }}
                      </div>
                    </div>
                  </el-popover>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </el-scrollbar>
    </div>

    <div v-else-if="currentView === 'Day'" class="dayView">
      <el-scrollbar wrap-style="max-height: 300px !important;">
        <table>
          <thead>
            <th style="width: 25%">All Day</th>
            <th style="width: 75%">
              <div v-if="getDayEvent(null)">
                <el-popover
                  placement="right"
                  trigger="hover"
                  v-if="
                    (getDayEvent(null) && getDayEvent(null).length) ||
                    (getDayRangeEvent(null) && getDayRangeEvent(null).length)
                  "
                >
                  <div class="today-date">
                    <p>
                      {{
                        monthsNames[currentDate.getMonth()] +
                        " " +
                        currentDate.getDate() +
                        "-" +
                        currentDate.getFullYear()
                      }}
                    </p>
                  </div>
                  <el-scrollbar wrap-style="max-height: 300px !important;">
                    <div
                      v-for="(ev, index) in getDayEvent(null)"
                      :key="'all_day_week_view' + index"
                      class="d-flex p-2"
                      @click="gotoEntityData(ev)"
                      :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                    >
                      <div
                        :style="`border-left: 3px solid ${
                          ev.color ? ev.color : '#f754a2'
                        }; padding-left: 5px;border-radius:3px 3px;`"
                        class="d-flex"
                      >
                        {{ ev.customData }}
                      </div>
                    </div>
                    <div
                      v-for="(ev, index) in getDayRangeEvent(null)"
                      :key="'day_view_hour1' + index"
                      class="d-flex p-2"
                      @click="gotoEntityData(ev)"
                      :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                    >
                      <div
                        :style="`border-left: 3px solid ${
                          ev.color ? ev.color : '#f754a2'
                        }; padding-left: 5px;border-radius:3px 3px;`"
                        class="d-flex"
                      >
                        {{ ev.customData }}
                        <div
                          class="ml-1"
                          v-if="
                            (ev.inputType == 'DATE_TIME_RANGE' ||
                              ev.inputType == 'DATE_RANGE') &&
                            ev.dates &&
                            ev.dates[0]
                          "
                          style="font-size: 10px; font-weight: bold"
                        >
                          <p>
                            {{ ev.dates[0] | moment("MM-DD-YYYY h:mm A")
                            }}<br />
                            {{ ev.dates[1] | moment("MM-DD-YYYY h:mm A") }}
                          </p>
                        </div>
                        <div class="ml-2" v-else></div>
                      </div>
                    </div>
                  </el-scrollbar>
                  <div class="weekday-container" slot="reference">
                    <div
                      v-for="(ev, index) in (getDayEvent(null) || []).slice(
                        0,
                        3
                      )"
                      :key="'allday_time_view' + index"
                      class="weekday-single"
                      :style="`background-color: ${
                        ev.color ? ev.color : '#f754a2'
                      }`"
                    >
                      {{ ev.customData | truncate(70, "...") }}
                    </div>
                    <div
                      v-if="
                        getDayRangeEvent(null) && getDayRangeEvent(null).length
                      "
                    >
                      <div
                        :style="`background-color: ${
                          getDayRangeEvent(null)[0].color
                            ? getDayRangeEvent(null)[0].color
                            : '#f754a2'
                        }; width: auto !important; height: 4px;`"
                      ></div>
                    </div>
                    <div
                      v-if="getDayEvent(null) && getDayEvent(null).length > 2"
                      :style="`font-size: 10px;`"
                    >
                      {{ "+" + (getDayEvent(null).length - 2) }}
                    </div>
                  </div>
                </el-popover>
              </div>
            </th>
          </thead>
          <tbody>
            <tr v-for="hour in hours" :key="hour">
              <td style="width: 25%">{{ hour }}</td>
              <td style="width: 75%">
                <div v-if="getDayEvent(hour)">
                  <el-popover
                    placement="right"
                    trigger="hover"
                    v-if="
                      (getDayEvent(hour) && getDayEvent(hour).length) ||
                      (getDayRangeEvent(hour) && getDayRangeEvent(hour).length)
                    "
                  >
                    <div class="today-date">
                      <p>
                        {{
                          monthsNames[currentDate.getMonth()] +
                          " " +
                          currentDate.getDate() +
                          "-" +
                          currentDate.getFullYear()
                        }}
                      </p>
                    </div>
                    <el-scrollbar wrap-style="max-height: 300px !important;">
                      <div
                        v-for="(ev, index) in getDayEvent(hour)"
                        :key="hour + 'hour_view' + index"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                          <div
                            class="ml-1 customdate"
                            v-if="ev.inputType == 'DATE_TIME'"
                          >
                            {{ ev.dates | moment("h:mm A") }}
                          </div>
                          <div class="ml-2" v-else></div>
                        </div>
                      </div>
                      <div
                        v-for="(ev, index) in getDayRangeEvent(hour)"
                        :key="hour + 'day_view_hour' + index"
                        class="d-flex p-2"
                        @click="gotoEntityData(ev)"
                        :style="`cursor: pointer; border-bottom: 1px solid rgb(216, 216, 216);`"
                      >
                        <div
                          :style="`border-left: 3px solid ${
                            ev.color ? ev.color : '#f754a2'
                          }; padding-left: 5px;border-radius:3px 3px;`"
                          class="d-flex"
                        >
                          {{ ev.customData }}
                          <div
                            class="ml-1"
                            v-if="
                              (ev.inputType == 'DATE_TIME_RANGE' ||
                                ev.inputType == 'DATE_RANGE') &&
                              ev.dates &&
                              ev.dates[0]
                            "
                            style="font-size: 10px; font-weight: bold"
                          >
                            <p>
                              {{ ev.dates[0] | moment("MM-DD-YYYY h:mm A")
                              }}<br />
                              {{ ev.dates[1] | moment("MM-DD-YYYY h:mm A") }}
                            </p>
                          </div>
                          <div class="ml-2" v-else></div>
                        </div>
                      </div>
                    </el-scrollbar>
                    <div class="weekday-container" slot="reference">
                      <div
                        v-for="(ev, index) in (getDayEvent(hour) || []).slice(
                          0,
                          3
                        )"
                        :key="hour + 'hour_day_view' + index"
                        class="weekday-single"
                        :style="`background-color: ${
                          ev.color ? ev.color : '#f754a2'
                        }`"
                      >
                        {{ ev.customData | truncate(70, "...") }}
                      </div>
                      <div v-if="getDayRangeEvent(hour)">
                        <!-- <div
                          :style="`background-color: ${
                            getDayRangeEvent(hour)[0].color
                              ? getDayRangeEvent(hour)[0].color
                              : '#f754a2'
                          }; width: auto !important; height: 4px;`"
                        ></div> -->
                      </div>
                      <div
                        v-if="getDayEvent(hour) && getDayEvent(hour).length > 2"
                        :style="`font-size: 10px;`"
                      >
                        {{ "+" + (getDayEvent(hour).length - 2) }}
                      </div>
                    </div>
                  </el-popover>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </el-scrollbar>
    </div>

    <!-- New event add modal -->
    <dialog-component
      :title="'Add New'"
      :visible="openNewEventModal"
      @before-close="resetEventModal"
      :fullscreen="true"
      class="el-dialog el-dialog-fullscreen"
      :containerWidth="'40%'"
    >
      <div v-loading="openNewEventLoading" class="mt-1 mb-15">
        <p style="margin-bottom: 3px">Add new</p>
        <el-select
          v-model="selectedEntity"
          placeholder="Select event"
          @change="fetchEntityDetails"
          clearable
        >
          <el-option
            v-for="op in this.selectedEntities"
            :key="op._id"
            :value="op._id"
            :label="op.name"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="selectedEntity && currentEntity && currentEntity._id">
        <!-- <PrimaryDataForm
          :fieldsListData="getPrimaryFields"
          :form="form"
        ></PrimaryDataForm> -->
        <fields-preview-template
          :templateData="currentEntity.templates[0].templateInfo"
          :has-next="false"
          :workflow-step-id="currentEntity.templates[0]._id"
          :entityDataId="null"
          :template-data-id="currentEntity.templates[0].templateDataId"
          :currentStep="currentEntity.templates[0]"
          :is-execute="true"
          :layout="'STANDARD'"
          :currentEntity="currentEntity"
          :isEdit="true"
          :entityData="null"
          :currentMenu="null"
          :entityDataExists="false"
          :fromAddWidget="true"
          @saveAndAssignRelation="saveAndAssignRelation"
        >
        </fields-preview-template>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetEventModal">Cancel</el-button>
        <el-button
          :loading="openNewEventLoading"
          type="primary"
          @click="addPrimaryDataToEntity"
          >Save</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      shouldCenterMonth: false,
      currentDate: new Date(),
      currentYear: new Date().getFullYear(),
      currentView: "Month",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours: [
        "12AM",
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
      ],
      monthsNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      openNewEventModal: false,
      openNewEventLoading: false,
      dialogVisible: false,
      selectedEntity: "",
      currentEntity: null,
      entityAllFields: [],
      form: {},
      primaryFields: [],
      selectedData: null,
      showChildEntityDataDialog: false,
      calenderDataByMonth: {},
      calenderDateRangeByMonth: {},
    };
  },
  components: {
    //   PrimaryDataForm: () =>
    //     import("@/components/entity/PrimaryDataCollectionForm"),

    FieldsPreviewTemplate: () =>
      import("@/components/templates/templateFieldsPreview.vue"),
  },
  props: [
    "calenderData",
    "isFromApplicationuser",
    "selectedEntities",
    "customDashboard",
    "parentDate",
    "parentView",
  ],

  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    getPrimaryFields() {
      if (this.currentEntity && this.currentEntity.primaryFields) {
        let fields = this.entityAllFields.map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }

          return e;
        });

        return this.currentEntity.primaryFields.filter((e) => {
          let found = fields.find((el) => {
            if (e.template_id == el.template_id && e.key == el.key) {
              return true;
            }
          });
          if (found) {
            return true;
          }
        });
      }
      return [];
    },
    currentMonth() {
      return this.currentDate.toLocaleString("default", {
        month: "",
        year: "numeric",
      });
    },
    weeks() {
      const startDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      const weeks = [];
      let week = [];
      let dayCounter = 1;
      console.log(dayCounter);

      // Add empty cells for days before the start of the month
      for (let i = 0; i < startDate.getDay(); i++) {
        week.push("");
      }

      // Add days of the month
      for (let i = 1; i <= endDate.getDate(); i++) {
        week.push(i);
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      // Add empty cells for days after the end of the month
      if (week.length > 0) {
        while (week.length < 7) {
          week.push("");
        }
        weeks.push(week);
      }

      return weeks;
    },
    weekDays() {
      const startDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate() - this.currentDate.getDay()
      );
      const weekDays = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        weekDays.push({ day: date.getDate(), month: date.getMonth() });
      }

      return weekDays;
    },
    getRequired() {
      return this.getPrimaryFields.filter(
        (e) => this.form[e.key] || typeof this.form[e.key] === "number"
      ).length;
    },

    getTotalCount() {
      return this.getPrimaryFields.filter((e) => e.key).length;
    },
  },
  methods: {
    setCalenderDates() {
      this.calenderDataByMonth = {};
      this.calenderData.forEach((e) => {
        if (e.inputType == "DATE_TIME_RANGE" || e.inputType == "DATE_RANGE") {
          if(e?.dates){
            let startD = e.dates[0];
            let endD = e.dates[1];
            if (this.calenderDateRangeByMonth[startD + "#" + endD]) {
              this.calenderDateRangeByMonth[startD + "#" + endD].push(e);
            } else {
              this.calenderDateRangeByMonth[startD + "#" + endD] = [e];
            }
          }
        } else {
          const date = moment(e.dates);
          const dayValue = parseInt(date.format("DD"));
          const month = parseInt(date.format("MM"));
          const year = date.format("YYYY");
          if (this.calenderDataByMonth[year + "#" + month + "#" + dayValue]) {
            this.calenderDataByMonth[year + "#" + month + "#" + dayValue].push(
              e
            );
          } else {
            this.calenderDataByMonth[year + "#" + month + "#" + dayValue] = [e];
          }
        }
      });
    },
    getFirstEvent(day) {
      let first = null;
      if (this.getDateEvent(day) && this.getDateEvent(day).length) {
        first = this.getDateEvent(day).find((e) => e.applyBackground);
      } else if (
        this.getDateRangeEvent(day) &&
        this.getDateRangeEvent(day).length
      ) {
        first = this.getDateRangeEvent(day).find((e) => e.applyBackground);
      }
      return first;
    },
    //goto today date
    SetTodayDate() {
      this.currentDate = new Date();
    },
    SeeNewData() {
      this.dialogVisible = true;
    },
    async saveAndAssignRelation() {
      this.openNewEventLoading = false;
      this.resetEventModal();
      this.$emit("newEntityData", { date: new Date(), view: this.currentView });
    },
    async addPrimaryDataToEntity() {
      try {
        this.openNewEventLoading = true;
        // this.primaryFields = [...this.getPrimaryFields];
        // if (this.form && this.getRequired === this.getTotalCount) {
        //   this.primaryFields = [...this.getPrimaryFields];

        //   this.primaryFields.forEach((e) => {
        //     if (
        //       e.input_type == "ENTITY" &&
        //       e.entity_id &&
        //       this.form[e.key] &&
        //       this.form[e.key + "/name"]
        //     ) {
        //       e["entity_data_key"] = e.key + "/name";
        //     }
        //   });
        //   //setting auto increment data
        //   let incrementFields = this.primaryFields.filter(
        //     (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
        //   );

        //   if (incrementFields && incrementFields.length) {
        //     await incrementFields.map(async (field) => {
        //       this.form[`${field.key}_info`] = {
        //         number: field.auto_increment_starting_number,
        //         prefix: field.auto_increment_prefix,
        //       };
        //     });
        //   }
        //   let selectedEntityData = this.selectedEntities.find(
        //     (e) => e._id == this.selectedEntity
        //   );
        //   if (selectedEntityData) {
        //   }
        //   await this.checkForDuplicateData();
        // } else {
        //   this.$notify.error({
        //     title: "Error",
        //     message: "Please fill all the fields in primary data",
        //   });
        // }
        this.$bus.$emit("save-template", false);
        this.openNewEventLoading = false;
      } catch (err) {
        console.log("addPrimaryDataToEntity", err);
      }
    },
    async checkForDuplicateData() {
      this.openNewEventLoading = true;
      let templateData = [];
      this.primaryFields.forEach((pf) => {
        let findIndex = templateData.findIndex(
          (e) => e.template_id == pf.template_id
        );
        if (findIndex > -1) {
          let existedData = templateData[findIndex];
          existedData.form = {
            ...existedData.form,
            ...{ [pf.key]: this.form[pf.key] },
          };
          templateData[findIndex] = { ...existedData };
        } else {
          templateData.push({
            template_id: pf.template_id,
            form: { [pf.key]: this.form[pf.key] },
          });
        }
      });
      let params = {
        entity_id: this.selectedEntity,
        parent_entityDataId: null,
        data: templateData,
      };
      await this.$store.dispatch("templatesData/checkDuplicateData", params);
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.currentEntity && this.currentEntity.name
                ? this.currentEntity.name + " is"
                : "data is") +
              " created.",
          });
          this.openNewEventLoading = false;
          return;
        }
        this.openNewEventLoading = false;
        this.createEntityData();
      } else {
        this.openNewEventLoading = false;
        this.$message({
          message: "Error while checking data",
          type: "error",
        });
      }
    },
    async createEntityData() {
      this.openNewEventLoading = true;
      let data = {
        primaryFields: this.currentEntity.primaryFields,
        formData: this.form,
        templates: this.currentEntity.templates,
        entity_id: this.selectedEntity,
      };
      await this.$store.dispatch("templatesData/createEntityPrimaryData", data);
      if (
        this.getPrimaryEntityDataStatus.message ===
        "Primary fields data required"
      ) {
        this.openNewEventLoading = false;
        this.$notify.error({
          title: "Error",
          message:
            "This form contains unique field.Cannot not create duplicate value",
        });
      } else {
        if (this.getPrimaryEntityDataStatus) {
          this.openNewEventLoading = false;
          this.$notify.success({
            title: "Success",
            message: "Primary Data saved successfully",
          });
          this.resetEventModal();
          this.$emit("newEntityData", {
            date: new Date(),
            view: this.currentView,
          });
        } else {
          this.openNewEventLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      }
    },
    resetEventModal() {
      this.currentEntity = null;
      this.entityAllFields = [];
      this.form = {};
      this.selectedEntity = "";
      this.openNewEventLoading = false;
      this.openNewEventModal = false;
    },
    async fetchEntityDetails(value) {
      this.currentEntity = null;
      if (value) {
        let selectedEntityData = this.selectedEntities.find(
          (e) => e._id == this.selectedEntity
        );
        if (selectedEntityData?.field_ids) {
          (selectedEntityData.field_ids || []).forEach((el) => {
            if (el && el.includes("#")) {
              let key = el.split("#")[2];
              this.$set(this.form, key, this.selectedData);
            }
          });
        }
        this.openNewEventLoading = true;
        await this.$store.dispatch("entities/fetchEntityById", {
          entity_id: value,
        });
        if (this.getEntityDataById) {
          this.currentEntity = this.getEntityDataById;
          this.entityAllFields = [];
          this.currentEntity.templates.forEach((e) => {
            if (e && e?.templateInfo?.sections && !e.is_repeatable) {
              this.entityAllFields = [
                ...this.entityAllFields,
                ...e.templateInfo.sections[0].fields.map((fl) => {
                  if (fl?.key && fl.key.includes("#")) {
                    fl.key = fl.key.split("#")[1];
                  }
                  fl.template_id = e.template_id;
                  return fl;
                }),
              ];
            }
          });
        }
        this.openNewEventLoading = false;
      }
    },
    openNewEvent(value, type, hour = "") {
      if (type == "MONTH") {
        this.selectedData = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          value
        );
      } else if (type == "WEEK") {
        hour = this.convertTime12to24(hour);
        this.selectedData = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          value,
          hour
        );
      }

      if (this.selectedEntities && this.selectedEntities.length == 1) {
        this.selectedEntity = this.selectedEntities[0]._id;
        this.fetchEntityDetails(this.selectedEntity);
      }
      this.availabelEvents = [];
      this.openNewEventModal = true;
    },

    convertTime12to24(time12h) {
      var modifier = time12h.slice(-2, 0);
      var hours = time12h.slice(0, -2);

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}`;
    },
    async gotoEntityData(data) {
      // this.showChildEntityDataDialog = false;

      // this.selectedChildEntityDataId = data.entity_data_id;

      // this.selectedChildEntityId = data.entity_id;

      // this.selectedChildEntityRefName = "test 1";
      // this.selectedChildEntityPrimaryDataValue = "test 2";

      // this.showChildEntityDataDialog = true;

      if (this.isFromApplicationuser) {
        let addQuery = {};
        addQuery["dataId"] = data.entity_data_id;
        addQuery["fromCustom"] = true;
        this.$router.push({
          path: `/ap/entity-view/${data.entity_id}`,
          query: {
            ...addQuery,
          },
        });
      } else {
        let addQuery = {};
        addQuery["dataId"] = data.entity_data_id;
        addQuery["fromCustom"] = true;
        this.$router.push({
          path: `/entity/view/${data.entity_id}`,
          query: { ...addQuery },
        });
      }
    },
    getDayRangeEvent(time) {
      let result = [];
      let date = this.calenderData.filter((e) => {
        if (e.inputType == "DATE_TIME_RANGE" || e.inputType == "DATE_RANGE") {
          const startDate = moment(e.dates[0]);
          const startHours = startDate.format("hA");
          const endDate = moment(e.dates[1]);
          const endHours = endDate.format("hA");
          const date = moment(this.currentDate);
          if (
            (date.isBefore(endDate) && date.isAfter(startDate) && !time) ||
            (date.isSame(startDate) && time == startHours) ||
            (date.isSame(endDate) && time == endHours)
          ) {
            return true;
          }

          // const startDate = moment(e.dates[0]);
          // const startDayValue = parseInt(startDate.format("DD")); // Extract day
          // const startMonth = parseInt(startDate.format("MM")); // Extract month

          // const endDate = moment(e.dates[1]);
          // const endDayValue = parseInt(endDate.format("DD")); // Extract day
          // const endMonth = parseInt(endDate.format("MM")); // Extract month
          // const endHours = endDate.format("hA");
          // if (
          //   (startDayValue < this.currentDate.getDate() &&
          //     startMonth <= this.currentDate.getMonth() + 1 &&
          //     endDayValue > this.currentDate.getDate() &&
          //     endMonth >= this.currentDate.getMonth() + 1 &&
          //     !time) ||
          //   (startDayValue == this.currentDate.getDate() &&
          //     startMonth == this.currentDate.getMonth() + 1 &&
          //     time == startHours) ||
          //   (endDayValue == this.currentDate.getDate() &&
          //     endMonth == this.currentDate.getMonth() + 1 &&
          //     time == endHours)
          // ) {
          //   return true;
          // }
        }
        // else {
        //   const date = moment(e.dates);

        //   const dayValue = parseInt(date.format("DD")); // Extract day
        //   const month = parseInt(date.format("MM")); // Extract month
        //   const year = date.format("YYYY"); // Extract year
        //   const hours = date.format("hA"); // Extract hour and format
        //   if (
        //     dayValue == this.currentDate.getDate() &&
        //     month == this.currentDate.getMonth() + 1 &&
        //     (year == this.currentDate.getFullYear() || e.everyYear)
        //   ) {
        //     if (e.inputType == "DATE" && !time) {
        //       return true;
        //     } else if (e.inputType == "DATE_TIME" && time == hours) {
        //       return true;
        //     }
        //   }
        // }
      });
      if (date && date.length) {
        result = [...date];
      }
      return result;
    },
    getDayEvent(time) {
      let result = [];
      let date = this.calenderData.filter((e) => {
        const date = moment(e.dates);

        const dayValue = parseInt(date.format("DD")); // Extract day
        const month = parseInt(date.format("MM")); // Extract month
        const year = date.format("YYYY"); // Extract year
        const hours = date.format("hA");
        if (
          dayValue == this.currentDate.getDate() &&
          month == this.currentDate.getMonth() + 1 &&
          (year == this.currentDate.getFullYear() || e.everyYear)
        ) {
          if (e.inputType == "DATE" && !time) {
            return true;
          } else if (e.inputType == "DATE_TIME" && time == hours) {
            return true;
          }
        }
      });
      if (date && date.length) {
        result = [...date];
      }
      return result;
    },
    getWeekEvent(day, time) {
      if (day) {
        let result = [];
        let date = this.calenderData.filter((e) => {
          const date = moment(e.dates);

          const dayValue = parseInt(date.format("DD")); // Extract day
          const month = parseInt(date.format("MM")); // Extract month
          const year = date.format("YYYY"); // Extract year
          const hours = date.format("hA");
          if (
            dayValue == day &&
            month == this.currentDate.getMonth() + 1 &&
            (year == this.currentDate.getFullYear() || e.everyYear)
          ) {
            if (e.inputType == "DATE" && !time) {
              return true;
            } else if (e.inputType == "DATE_TIME" && time == hours) {
              return true;
            }
          }
        });
        if (date && date.length) {
          result = [...date];
        }
        return result;
      }
      return [];
    },
    getDayRangeWeekEvent(day, time, month) {
      if (day) {
        let result = [];
        let date = this.calenderData.filter((e) => {
          // const startDate = moment(e.dates[0]);
          // const endDate = moment(e.dates[1]);
          // var timeT = time ? this.convertTime12to24(time) : 0;
          // let date = moment(
          //   this.currentDate.getFullYear(),
          //   this.currentDate.getMonth(),
          //   day,
          //   timeT
          // );
          if (e.inputType == "DATE_TIME_RANGE" || e.inputType == "DATE_RANGE") {
            //   if (
            //     (date.isBefore(endDate) && date.isAfter(startDate)) ||
            //     date.isSame(startDate) ||
            //     date.isSame(endDate)
            //   ) {
            //     return true;
            //   }
            // }
            // if (e.inputType == "DATE_TIME_RANGE") {
            //   const startDate = moment(e.dates[0]);
            //   const startDayValue = parseInt(startDate.format("DD")); // Extract day
            //   const startMonth = parseInt(startDate.format("MM")); // Extract month
            //   const startHours = startDate.format("hA");

            //   const endDate = moment(e.dates[1]);
            //   const endDayValue = parseInt(endDate.format("DD")); // Extract day
            //   const endMonth = parseInt(endDate.format("MM")); // Extract month
            //   const endHours = endDate.format("hA");
            //     if (
            //       (startDayValue < day &&
            //         startMonth <= this.currentDate.getMonth() + 1 &&
            //         endDayValue > day &&
            //         endMonth >= this.currentDate.getMonth() + 1 &&
            //         !time) ||
            //       (startDayValue == day &&
            //         startMonth == this.currentDate.getMonth() + 1 &&
            //         time == startHours) ||
            //       (endDayValue == day &&
            //         endMonth == this.currentDate.getMonth() + 1 &&
            //         time == endHours)
            //     ) {
            //       return true
            //     }
            var startD = new Date(e.dates[0]);
            var endD = new Date(e.dates[1]);
            var timeT = time ? this.convertTime12to24(time) : 0;
            var checking = new Date(
              this.currentDate.getFullYear(),
              month,
              day,
              timeT
            );
            if (startD <= checking && checking <= endD) {
              if (parseInt(this.getCurrentDate().split("/")[0]))
                if (time == null) {
                  return true;
                }
              return false;
            }
          }
        });
        if (date && date.length) {
          result = [...date];
        }
        return result;
      }
      return [];
    },
    getDateRangeEvent(day) {
      if (day) {
        let result = [];
        var checking = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          day
        );
        if (Object.keys(this.calenderDateRangeByMonth).length > 0) {
          Object.keys(this.calenderDateRangeByMonth).forEach(e => {
            var startD = new Date(e.split('#')[0]);
            var endD = new Date(e.split('#')[1]);
            if (startD <= checking && checking < endD) {
              result = [...result, ...this.calenderDateRangeByMonth[e]];
            }
          });
        }
        // let date = this.calenderData.filter((e) => {
        //   if (e.inputType == "DATE_TIME_RANGE" || e.inputType == "DATE_RANGE") {
        //     //const startDate = moment(e.dates[0]);
        //     //const startDayValue = parseInt(startDate.format("DD")); // Extract day
        //     //const startMonth = parseInt(startDate.format("MM")); // Extract month
        //     //const startyear = startDate.format("YYYY"); // Extract year

        //     //const endDate = moment(e.dates[1]);
        //     // const endDayValue = parseInt(endDate.format("DD")); // Extract day
        //     //const endMonth = parseInt(endDate.format("MM")); // Extract month
        //     //  const endYear = endDate.format("YYYY"); // Extract year
        //     var startD = new Date(e.dates[0]);
        //     var endD = new Date(e.dates[1]);
        //     var checking = new Date(
        //       this.currentDate.getFullYear(),
        //       this.currentDate.getMonth(),
        //       day
        //     );
        //     if (startD <= checking && checking < endD) {
        //       // if (
        //       //   e.inputType == "DATE_TIME_RANGE" &&
        //       //   startDayValue <= day &&
        //       //   startMonth <= this.currentDate.getMonth() + 1 &&
        //       //   endDayValue >= day &&
        //       //   endMonth >= this.currentDate.getMonth() + 1 &&
        //       //   (e.everyYear ||
        //       //     (startyear <= this.currentDate.getFullYear() &&
        //       //       endYear >= this.currentDate.getFullYear()))
        //       // ) {
        //       return true;
        //     }
        //   }
        // });
        // if (date && date.length) {
        //   result = [...date];
        // }

        return result;
      }
      return [];
    },
    getDateEvent(day) {
      if (day) {
        let result = [];
        // let date = this.calenderData.filter((e) => {
        //   const date = moment(e.dates);

        //   const dayValue = parseInt(date.format("DD")); // Extract day
        //   const month = parseInt(date.format("MM")); // Extract month
        //   const year = date.format("YYYY"); // Extract year

        //   if (
        //     dayValue == day &&
        //     month == this.currentDate.getMonth() + 1 &&
        //     (year == this.currentDate.getFullYear() || e.everyYear)
        //   ) {
        //     return true;
        //   }
        // });
        let key =
          this.currentDate.getFullYear() +
          "#" +
          (this.currentDate.getMonth() + 1) +
          "#" +
          day;

        if (
          this.calenderDataByMonth?.[key] &&
          this.calenderDataByMonth[key].length
        ) {
          result = [...this.calenderDataByMonth[key]];
        }
        return result;
      }
      return [];
    },
    getCurrentTimeToWeek() {
      if (this.currentView == "Week") {
        return (
          this.monthsNames[this.currentDate.getMonth()] +
          "-" +
          this.currentDate.getFullYear()
        );
      }
    },
    getCurrentDate() {
      if (this.currentView == "Month") {
        return (
          this.monthsNames[this.currentDate.getMonth()] +
          "-" +
          this.currentDate.getFullYear()
        );
      }
      return this.currentDate.toLocaleDateString();
    },
    //backward to previous view
    prev() {
      if (this.currentView === "Month") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() - 1,
          1
        );
        this.shouldCenterMonth = true;
      } else if (this.currentView === "Week") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate() - 7
        );
      } else if (this.currentView === "Day") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate() - 1
        );
      }
      if (this.currentYear != this.currentDate.getFullYear()) {
        this.currentYear = this.currentDate.getFullYear();
        this.$emit("newEntityData", {
          date: this.currentDate,
          view: this.currentView,
        });
      }
    },
    //forward to next view
    next() {
      if (this.currentView === "Month") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() + 1,
          1
        );
        this.shouldCenterMonth = true;
      } else if (this.currentView === "Week") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate() + 7
        );
      } else if (this.currentView === "Day") {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate() + 1
        );
      }
      if (this.currentYear != this.currentDate.getFullYear()) {
        this.currentYear = this.currentDate.getFullYear();
        this.$emit("newEntityData", {
          date: this.currentDate,
          view: this.currentView,
        });
      }
    },
    changeView(view) {
      this.currentView = view;
    },
    isCurrentDay(day) {
      const today = new Date();
      return (
        day !== "" &&
        this.currentDate.getMonth() === today.getMonth() &&
        this.currentDate.getFullYear() === today.getFullYear() &&
        day === today.getDate()
      );
    },
  },
  mounted() {
    this.setCalenderDates();
    this.currentDate = this.parentDate;
    this.currentYear = this.currentDate.getFullYear();
    this.currentView = this.parentView;
  },
};
</script>

<style scoped lang="scss">
.weekday-container {
}
.weekday-single {
  width: 100% !important;
  height: 14px;
  border-radius: 4px;
  font-size: 9px;
  color: white;
  margin-top: 2px;
}
.event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  min-height: 6px;
}
.single-event {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.calendar {
  border-top: 1px solid #ccc;
  /* padding: 10px; */
  width: 100%;
}

.calendar-header {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  gap: 10px;
  width: 100% !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  // padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
}

th {
  font-weight: bold;
}

.current-day {
  background-color: lightblue;
}

.cell-content {
  position: relative;
  padding: 5px;
}

.event {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 2px;
}

.event:first-child {
  margin-top: 5px;
}

/* Increase the width of each day */
td {
  position: relative;
  width: 120px;
  height: 35px; /* Adjust the width as needed */
}
.view-buttons {
  float: right !important;
}

.view-buttons button {
  margin-left: 10px;
}
.current-date {
  font-weight: bold;
  margin-right: auto;
}
.centered {
  text-align: center;
}
.today-date {
  font-weight: 800;
  font-size: 13px;
}
.custom-popover {
  font-size: 10px;
}
.customdate {
  font-weight: 700;
  font-size: 12px;
}
</style>
